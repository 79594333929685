import civil from '../../assets/images/services/balfour-civil.jpg'
import manpower from '../../assets/images/services/balfour-manpower-back.jpg'
import rental from '../../assets/images/services/balfour-rental.jpg'
import scafolding from '../../assets/images/services/balfour-scafolding.jpg'
import generator from '../../assets/images/services/balfour-generator.jpg'
import industrial from '../../assets/images/services/balfour-industrial-material.jpg'






const services = [
    {
      image: manpower,
      title: "Manpower Supply",
      shortDescription: 'Skilled workers for various trades and tasks.',

      description: 'The greatest asset of the company is the employees/workers. We got Almost All the categories like Electricians, Electrical Foreman, Plumbers, Mason, Duct Fitters, pipe Fitters, Scaffolders, Welders, pipe Insulators, general Foreman or Supervisor, General Labours and Helpers etc.',
    },
    {
      image: rental,
      title: "Equipment Rentals",
    shortDescription: 'New machinery for efficient project completion.',

      description: 'BalFour is one of the machinery equipment company in the kingdom. We provide the equipment Rental services with new fleets of equipment which enhance the client work and speed up in achieving the targets on time. Our equipments are operated by experienced operators having knowledge of safety and technical aspects and they are well trained operators at all kinds of environment.'
    },
    {
      image: industrial,
      title: "Industrial Material",
    shortDescription: 'High-quality valves, fittings, and sanitary materials.',

      description: 'BalFour supplies industrial Valves, Fittings, PVC Fittings & Valves and Sanitary materials. We are leaders in sourcing and supply of all industrial material. Our Material sourcing team is with extreme product knowledge and sourcing support in all over the world.'
    },
    {
      image: scafolding,
      title: "Scaffolding Supply",
    shortDescription: 'Steel scaffolding and formwork solutions.',

      description: 'BalFour is one of the most prominent & most successful companies for sell & rent the steel scaffolding & formwork & casting molded mineral in the Middle East & the global reach of the market.'
    },
    {
      image: civil,
      title: "Civil Works",
    shortDescription: 'Expert services in site development and construction.',

      description: 'At BalFour, we provide expert civil engineering services, including site development, road construction, and structural concrete work. Our dedicated team ensures projects are completed efficiently and to the highest standards, supporting the infrastructure growth of Saudi Arabia with precision and excellence. Trust us for reliable and innovative solutions in all your civil works needs.'
    },
    {
      image: generator,
      title: "Generator Hire",
    shortDescription: 'Silent diesel generators for all power needs.',

      description: 'BalFour offers extensive expertise and experience for all your power requirements on projects of all sizes. From small scale hiring to multiple machine packages Instant Power can meeet your demands with our modern up to date hiire fleet of Super Silent Diesel Generators from 20Kva, Distribuition Boards, Bunded Fuel Tanks, Cables, Transformers and Ancillaries.'
    },
  ];

  export default services;