import React from 'react';
import theme from "../../assets/theme";

const SideHeader = ({ firstText, secondText, color, secondColor,  className}) => {
  return (
    <h1 className={`${className ? className : 'text-center'} customfont`}>
      <span style={{ fontSize: '2rem', color: color || 'inherit' }}>{firstText}</span>
      <span style={{ fontSize: '2rem', color: secondColor || theme.palette.background.primary }}> {secondText}</span>
    </h1>
  );
};

export default SideHeader;
