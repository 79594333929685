import React from "react";
import { Box, Button } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SideHeader from "./elements/sideHeader";
import theme from "../assets/theme";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import services from "./elements/servicesData";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 10,
  },
};

const OurServices = () => {
  const navigate = useNavigate();

  const handleCardClick = (service) => {
    // Assuming each service object has a unique slug or ID
    // navigate(`/service#${service.title.toLowerCase().replace(/ /g, '-')}`);
    navigate(`/service`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        py: { xs: 1, sm: 1 },
        bgcolor: "white",
      }}
    >
      <style>
        {`
          .carousel-blur-left, .carousel-blur-right {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 50px;
            z-index: 2;
          }
          .carousel-blur-left {
            left: 0;
            background: linear-gradient(to right, white, transparent);
          }
          .carousel-blur-right {
            right: 0;
            background: linear-gradient(to left, white, transparent);
          }
          @media (max-width: 464px) {
            .carousel-blur-left, .carousel-blur-right {
              display: none;
            }
          }
        `}
      </style>
      <div className="container mt-5 mb-5 text-center">
        <div className="services-section" style={{ padding: "30px 0" }}>
          <SideHeader firstText="Explore Our" secondText="Services" />
          <div className="carousel-container my-5">
            <div className="carousel-blur-left"></div>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              arrows={false}
            >
              {services.map((service, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    transition: "transform 0.3s",
                  }}
                  className="service-card"
                >
                  <Card
                    sx={{
                      maxWidth: 300,
                      margin: "10px",
                      width: "100%",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    onClick={() => handleCardClick(service)}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="170"
                        image={service.image}
                        alt={service.title}
                        sx={{ filter: "brightness(80%)" }}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          style={{ fontFamily: "poppins", fontWeight: "300" }}
                        >
                          {service.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ fontFamily: "poppins", fontWeight: "200" }}
                        >
                          {service.shortDescription}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              ))}
            </Carousel>
            <div className="carousel-blur-right"></div>
          </div>
          <Button
            variant="outlined" // Ensure the button is outlined
            sx={{
              borderColor: theme.palette.primary.main, // Set the border color to match the theme
              color: theme.palette.primary.main, // Set text color to match the theme
              "&:hover": {
                borderColor: theme.palette.primary.dark, // Darker border color on hover
                color: theme.palette.primary.dark, // Darker text color on hover
              },
            }}
            onClick={handleCardClick}
          >
            Explore More
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default OurServices;
