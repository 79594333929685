import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SideHeader from "./elements/sideHeader";

export default function AboutUsSection() {
  return (
    <>
      <Box
        id="aboutus"
        sx={{
          py: { xs: 4, sm: 6 },
          color: "white",
          bgcolor: "#06090a",
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <SideHeader firstText={"About"} secondText={"BalFour"} className={'text-sm-left'} />
            <Typography variant="body" sx={{ color: "grey.400" }}>
              BalFour is your trusted partner for manpower, heavy equipment
              rentals, and engineering services in Saudi Arabia. Our commitment
              to quality and excellence drives us to provide unparalleled
              services, fostering long-term partnerships through value-added
              solutions and personalized care. With a team of industry experts
              and a vision for growth, we are dedicated to meeting your unique
              needs with professionalism and integrity.
            </Typography>
          </Box>
        </Container>

        {/* mobile view */}
      </Box>
    </>
  );
}
