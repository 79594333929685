import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import SideHeader from './elements/sideHeader';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Expertise and Experience',
    description: 'Years of industry knowledge and hands-on experience.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Quality Assurance',
    description: 'High standards of quality in every service we offer.',
  },
  {
    icon: <SafetyCheckIcon />,
    title: 'Safety Commitment',
    description: 'At BalFour, safety is a priority in everything we do.',
  },
  {
    icon: <AddLocationAltIcon />,
    title: 'Nationwide Reach',
    description: 'Expanding presence with multiple offices across Saudi Arabia.',
  },
];

export default function Features() {
  const [inViewItems, setInViewItems] = useState([]);
  const itemRefs = useRef([]);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, items.length);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInViewItems((prev) => [
            ...prev,
            entry.target.getAttribute('data-index'),
          ]);
        }
      });
    }, {
      threshold: 0.1, // Trigger when 10% of the element is in view
    });

    itemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      itemRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <SideHeader firstText={"Why Choose"} secondText={"BalFour"} />
        </Box>
        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Stack
                ref={(el) => (itemRefs.current[index] = el)}
                direction="column"
                color="inherit"
                component={Card}
                spacing={2}
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  bgcolor: 'grey.900',
                  borderRadius: '16px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  transform: inViewItems.includes(index.toString()) ? 'translateY(0)' : 'translateY(20px)',
                  opacity: inViewItems.includes(index.toString()) ? 1 : 0,
                  transition: `transform 0.5s ${index * 0.1}s ease-out, opacity 0.5s ${index * 0.1}s ease-out`,
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                data-index={index}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 56,
                    height: 56,
                    bgcolor: 'primary.main',
                    borderRadius: '50%',
                    mb: 2,
                  }}
                >
                  <Box sx={{ color: 'white', fontSize: '24px' }}>{item.icon}</Box>
                </Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
