import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SideHeader from './elements/sideHeader';
import { Container } from 'react-bootstrap';

const faqData = [
  {
    question: "What services does BalFour provide?",
    answer: "BalFour offers manpower, heavy equipment rentals, and engineering services. We are committed to delivering high-quality solutions tailored to your needs."
  },
  {
    question: "Where is BalFour located?",
    answer: "BalFour is based in Saudi Arabia. We serve clients throughout the region, ensuring excellence and professionalism in all our engagements."
  },
  {
    question: "How can I contact BalFour?",
    answer: "You can contact us through our website's contact form or by calling our customer service number. We are always ready to assist you with your inquiries."
  },
  {
    question: "What sets BalFour apart from other companies?",
    answer: "Our commitment to quality, excellence, and personalized care sets us apart. With a team of industry experts and a vision for growth, we strive to meet your unique needs with professionalism and integrity."
  },
  {
    question: "Can I get a customized solution for my project?",
    answer: "Absolutely! BalFour specializes in providing value-added solutions tailored to your specific requirements. Contact us to discuss your project, and we'll work together to achieve your goals."
  }
];

function FAQSection() {
  return (
    <Box id="faq" sx={{ p: 2, backgroundColor: '#f7f7f7', borderRadius: 2, boxShadow: 3 }} >
      {/* <Typography variant="h4" component="h2" gutterBottom align="center">
        Frequently Asked Questions
      </Typography> */}

      <SideHeader firstText={"Frequently Asked"} secondText={"Questions"} color={'black'} />


      
      {faqData.map((faq, index) => (
        <Container >
            <Accordion key={index} sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{fontFamily: 'poppins', fontWeight:'400'}}>{faq.question} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: 'poppins', fontWeight:'300'}}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
        </Container>
      ))}
    </Box>
  );
}

export default FAQSection;
