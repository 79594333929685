import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import theme from "../assets/theme";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import heroImage from "../assets/images/hero-img-balfour.jpg";

export default function Hero() {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -128,
    });
  };

  const handleNavigation = (path, sectionId = null) => {
    if (location.pathname !== path) {
      navigate(path);
      if (sectionId) {
        setTimeout(() => scrollToSection(sectionId), 300); // Delay to ensure navigation completes
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else if (sectionId) {
      scrollToSection(sectionId);
    }
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      })}
    >
      <div className="row mx-4">
        <div className="col-12 col-md-7 d-flex justify-content-center">
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              // textAlign : 'left',
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
              // alignItems: isSmallDevice ? 'center' : 'flex-start',
              textAlign: { xs: "center", sm: "center", md: "left" },
            }}
          >
            <Stack
              spacing={2}
              useFlexGap
              sx={{ width: { xs: "100%", sm: "70%" } }}
            >
              <h1 className="heroHeading" style={{ fontSize: "2rem" }}>
                {" "}
                شركة وسيم احمد سبيتي للمقاولات
              </h1>

              <h1 className="heroHeading heroTyping">
                Powering Progress with Excellence -{" "}
                <span style={{ color: theme.palette.background.primary }}>
                  {" "}
                  BalFour Contracting Company
                </span>
              </h1>

              <div className="d-block d-md-none">
             {/* eslint-disable-next-line */}
                <img
                  src={heroImage}
                  className="img-fluid heroImageMobile"
                  alt="Balfour Hero Image"
                />
              </div>

              <Typography
                // textAlign="center"
                color="text.secondary"
                sx={{ width: { sm: "100%", md: "100%" } }}
              >
                Reliable Manpower, Top-Quality Equipment, and Expert Engineering
                Services in Saudi Arabia
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                // alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleNavigation("/", "aboutus")}
                >
                  Explore
                </Button>
                <a
                href="https://drive.google.com/file/d/1dZpPBxFjBxYGvdGey6n3GO5X_cZztMlD/view?usp=drive_link"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none'}}
                >
                <Button variant="contained" color="primary" style={{width:'100%'}}>
                  Download Broucher
                </Button>
                </a>
              </Stack>
            </Stack>
          </Container>
        </div>
        <div className="col-12 col-md-5 ">
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
            }}
            className="d-none d-md-block"
          >
            {/* eslint-disable-next-line */}
            <img
              src={heroImage}
              className="img-fluid heroImage"
              alt="Responsive image"
            />
          </Container>
        </div>
      </div>
    </Box>
  );
}
