import React from "react";
import Hero from "../components/hero";
import Features from "../components/features";
// import LogoCollection from "../components/logoCollection";
import OurServices from "../components/ourServices";
import AboutUsSection from "../components/aboutUsSection";

export default function Home() {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Hero />
      {/* <LogoCollection /> */}
      <AboutUsSection />
      <OurServices />
      <Features />
    </div>
  );
}
