import React from 'react';
import { Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Typography from '@mui/material/Typography';
import { CSSTransition } from 'react-transition-group';


const ImageTextSection = ({ align, inProp, imageSrc, heading, description }) => {
  const isLeftAligned = align === 'left';

  return (
    <Container style={{ maxWidth: '800px' }}>
      <CSSTransition in={inProp} timeout={300} classNames="fade">
        <div className="row">
          <div className={`col-lg-6 ${isLeftAligned ? '' : 'order-lg-2'} order-1 d-flex align-items-center `}>
            <div className="mx-lg-0" style={{ maxWidth: '100%', width: '400px' }}>
              <Image 
                src={imageSrc} 
                className="custom-image" 
                fluid 
              />
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center order-lg-1 order-2 py-2">
            <div>
              <h1 className="customfont">
                <span style={{ fontSize: '1.5rem', color: 'black' }}>{heading}</span>
              </h1>
              <Typography
                color="text.secondary"
                sx={{ width: '100%', fontFamily: 'Poppins', fontWeight: '300', fontSize: '0.8rem' }}
              >
                {description}
              </Typography>
            </div>
          </div>
        </div>
      </CSSTransition>
    </Container>
  );
};

export default ImageTextSection;
