import React, { useState } from "react";
import emailjs from "emailjs-com";
import SideHeader from "./elements/sideHeader";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader

export default function Form({ allowUpload = false }) {
  const [formData, setFormData] = useState({
    name: "",
    from_name: "",
    email: "",
    message: "",
    file: null,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const recaptchaResponse = window.grecaptcha.getResponse();
    if (recaptchaResponse.length === 0) {
      setSnackbarMessage("Please verify you are not a robot.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); // Set loading to true before sending the email

    emailjs
      .send("balfour-contact", "balfour_contact", formData, "kzuz2N0nKS51Fw_3a")
      .then((response) => {
        setLoading(false); // Set loading to false after the email is sent
        setSnackbarMessage("Email sent successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setFormData({ name: "", from_name: "", email: "", message: "", file: null });
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        setLoading(false); // Set loading to false in case of an error
        setSnackbarMessage("Failed to send email. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("FAILED...", err);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div className="form-container text-start">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <SideHeader
              firstText={"Contact us"}
              color={"White"}
              className="text-start"
            />

            <label htmlFor="name">Name</label>
            <input
              required
              name="from_name"
              id="name"
              value={formData.from_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              required
              name="email"
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="textarea">Message</label>
            <textarea
              name="message"
              id="textarea"
              cols="50"
              rows="10"
              value={formData.message}
              onChange={handleChange}
            />
            <div
              className="g-recaptcha mt-4"
              data-sitekey="6LcZhBkqAAAAAH_5WDxKRzpIQtsGJEAtBGmSmACN"
            ></div>
          </div>
          {allowUpload && (
            <div className="form-group">
              <label htmlFor="file">Upload File</label>
              <input
                type="file"
                name="file"
                id="file"
                accept="application/pdf"
                onChange={handleChange}
                required={allowUpload} // Make this field mandatory if allowUpload is true
              />
            </div>
          )}
          <button type="submit" className="form-submit-btn" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Submit'} {/* Show loader or button text */}
          </button>
        </form>
        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
