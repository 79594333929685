import React from "react";
import Box from "@mui/material/Box";
import theme from ".././assets/theme";

export default function contact() {
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: theme.palette.background.bg,
        }}
      >
      </Box>
    </div>
  );
}
