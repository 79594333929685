// theme.js
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    background: {
      primary: '#2196f3',
      secondary: '#FF0000',
      contact: 'black' ,
      bg: '#071952' // Example primary color
    },
    textFieldBorder: {
      primary: '#ffffff', // Example text color
    },
  },
  typography: {
    // Define typography styles if needed
    primary: 'black',
    secondary: 'white',
  },
  // Add other global styles or overrides here
});

export default theme;
