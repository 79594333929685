import React from "react";
import { Container } from "react-bootstrap";
import SideHeader from "./elements/sideHeader";
import Form from "./form";

export default function ContactForm() {

  return (
    <Container>
      <div className="row d-flex align-items-center">
        <div className="col-12 col-md-6 contact-text-container align-items-center text-start">
          <SideHeader
            firstText={"Have any questions? We're happy to answer them!"}
            color={"White"}
            className="text-start"
          />

          <section color="white">
            <div>
              <i className="fas fa-phone-alt "></i>
              <span className="contact-text">
                Phone: <a href="tel: +966 510831933"> +966 510831933</a>
              </span>
            </div>

            
            <div>
              <i className="fas fa-envelope"></i>
              <span className="contact-text">
                Email:{" "}
                <a href="mailto: contact@balfourco.com"> contact@balfourco.com </a>
              </span>
            </div>

            

            <div>
              <i className="fas fa-map-marker-alt"></i>
              <span className="contact-text">
                Address:{" "}
                  Al Jubail city - 35514, <br />
                  Eastern province, kingdom of Saudi Arabia
              </span>
            </div>
          </section>
          <div className="map-responsive">
            <div className="col d-flex align-items-stretch">
            {/* eslint-disable-next-line */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28437.94608791377!2d49.632672799976575!3d27.00667013791864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a05e4e3e9251%3A0x37d923d58849172!2sAl%20Jubail%2035514%2C%20Saudi%20Arabia!5e0!3m2!1sen!2sin!4v1721827535363!5m2!1sen!2sin"
                // width="600"
                // height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-start pt-2">
      <Form />
      </div>
        
      </div>

      

    </Container>
  );
}
