import React from "react";
import { Container } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { Stack, Box, Skeleton } from "@mui/material";
import SideHeader from "../../components/elements/sideHeader";
import services from "../../components/elements/servicesData";
import FAQSection from "../../components/faqSection";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import themeColor from "../../assets/theme";



import { useInView } from "react-intersection-observer";
import ImageTextSection from "./imageTextSection";

const ServiceCard = ({ service, align }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <Box
      ref={ref}
      sx={{
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 0.6s ease, transform 0.6s ease',
      }}
    >
      <ImageTextSection
        align={align}
        imageSrc={service.image}
        heading={service.title}
        description={service.description}
      />
    </Box>
  );
};

export default function Services() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    // Simulate data fetching
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout to simulate loading duration
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const LoadingSkeleton = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 2,
        mb: 4,
      }}
    >
      <Skeleton variant="rectangular" width={isMobile ? "100%" : 300} height={140} />
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
      </Box>
    </Box>
  );

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          paddingTop: "100px",
          height: "170px",
          backgroundColor: themeColor.palette.background.bg,
        }}
      >
        <SideHeader
          firstText={"Our"}
          secondText={"Services"}
          color={"White"}
          secondColor={"white"}
        />
      </Box>

      <Container style={{ maxWidth: "800px" }} className="my-4">
        <Stack spacing={4}>
          <Typography
            variant="body"
            sx={{ fontWeight: "400", paddingBottom: '20px' }}
          >
            <span style={{color: themeColor.palette.background.primary}}>At BalFour,</span> we pride ourselves on delivering top-notch services that
            cater to the diverse needs of our clients in Saudi Arabia. Our
            commitment to quality and excellence ensures that we provide
            unparalleled support and solutions, fostering long-term partnerships
            through value-added services and personalized care. Explore our range
            of services designed to meet your unique requirements with
            professionalism and integrity.
          </Typography>
          {loading
            ? Array.from(new Array(6)).map((_, index) => (
                <LoadingSkeleton key={index} />
              ))
            : services.map((service, index) => (
                <ServiceCard
                  key={index}
                  service={service}
                  align={index % 2 === 0 ? "left" : "right"}
                />
              ))}
        </Stack>
      </Container>

      <FAQSection />
    </div>
  );
}
