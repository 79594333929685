import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import { Facebook, Instagram } from "@mui/icons-material";
import theme from "../assets/theme";
import ContactForm from "./contactForm";
import logo from "../assets/balfour.png";

const logoStyle = {
  width: "auto",
  height: "110px",
};

function Copyright() {
  return (
    <Typography
      variant="body2"
      mt={1}
      color="#ffffff93"
      fontFamily={"poppins"}
      fontWeight={300}
      fontSize={'12px'}
    >
      {"Copyright © "}
      BalFour Contracting Company&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <div id="contact">
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 8 },
          py: { xs: 6, sm: 10 },
          textAlign: { sm: "center", md: "left" },
          backgroundColor: theme.palette.background.bg,
          px: { xs: 0, sm: 0, md: 0 },
        }}
      >
        <ContactForm />
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: { xs: 4, sm: 8 },
              width: "100%",
              borderTop: "0.5px solid",
              borderColor: "#ffffff39",
              // px: '50px',
            }}
            // className="row d-flex align-item-end"
          >
            <div className="col-12 text-center">
              <Box sx={{ width: { xs: "100%", sm: "100%" }, color: "white" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      boxShadow: "0px 4px 12px rgba(173, 216, 230, 0.7)", // light blue shadow
                      borderRadius: "50%", // makes the shadow rounded
                      display: "inline-block",
                      padding: "0px", // ensures the shadow is visible around the image
                    }}
                  >
                    <img src={logo} style={logoStyle} alt="logo of BalFour" />
                  </Box>
                </Box>
              </Box>

              <Typography
                color="white"
                paddingTop={"16px"}
                className="custom-font"
                style={{fontSize: '1.2rem'}}
              >
                BalFour Contracting Company
              </Typography>
              <Typography color="white">C.R. 2053126792</Typography>
              <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                className="col-12 d-flex justify-content-center pt-4"
                useFlexGap
                sx={{
                  color: "white",
                }}
              >
                <IconButton
                  color="inherit"
                  href=""
                  aria-label="X"
                  sx={{ alignSelf: "center" }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href=""
                  aria-label="LinkedIn"
                  sx={{ alignSelf: "center" }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href=""
                  aria-label="Instagram"
                  sx={{ alignSelf: "center" }}
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  color="inherit"
                  href=""
                  aria-label="Facebook"
                  sx={{ alignSelf: "center" }}
                >
                  <Facebook />
                </IconButton>
              </Stack>

              <div className="mt-4">
                <Copyright />
              </div>
            </div>
          </Box>
        </Container>
      </Container>
    </div>
  );
}
